<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12"> <Header /> </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <ImageUpload />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ImageUpload from "@/components/dataview/ImageUpload";

export default {
  components: {
    ImageUpload,
  },

  mounted() {
    this.$store.dispatch("menu/setQuickLink", "home");
  },
};
</script>